<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 h2back">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span>
        Editar equipo
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editAsset">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6" class="body-info product_info">
              <b-form-group :label="'Categoria'" label-for="category-parent">
                <v-select
                  v-model="parent"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectCategoriesParent"
                  :disabled="true"
                  :placeholder="'Categoria'"
                />
              </b-form-group>
              <b-form-group
                :label="'Subcategoría'"
                label-for="product-category"
              >
                <v-select
                  v-model="category"
                  label="name"
                  :options="selectCategories"
                  :filterable="false"
                  :searchable="false"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group :label="'Productos'" label-for="product">
                <v-select
                  ref="products"
                  v-model="product"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="selectProducts"
                  :disabled="true"
                  :placeholder="'Seleccionar producto'"
                />
              </b-form-group>

              <b-form-group :label="'Zona'" label-for="location">
                <validation-provider
                  #default="{ errors }"
                  :name="'Zona'"
                  rules="required"
                >
                  <v-select
                    v-model="location"
                    label="name"
                    :filterable="false"
                    :searchable="true"
                    :options="project.locations"
                    :placeholder="'Seleccionar Zona'"
                  />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group :label="'Cliente'">
                <v-select
                  v-model="project.client.name"
                  label="Cliente"
                  :placeholder="'Cliente'"
                  disabled
                  :options="[project.client]"
                />
              </b-form-group>
              <b-form-group :label="'Obra'">
                <v-select
                  v-model="project.name"
                  label="Obra"
                  :placeholder="'Obra'"
                  disabled
                />
              </b-form-group>
              <b-form-group 
                v-if="asset.category_parent.ref === 'cortinas'" 
                :label="'Direccion mac'"
              >
                <v-select
                  v-model="asset.mac_address"
                  label="Direccion mac"
                  :placeholder="'Direccion mac'"
                  disabled
                />
                <pre></pre>
              </b-form-group>
            </b-col>
            <b-col sm="6" class="body-info">
              <b-form-group
                :label="'Descripción'"
                label-for="asset-description"
              >
                <quill-editor v-model="asset.description" />
              </b-form-group>

              <b-form-group :label="'Documentos'" label-for="documents">
                <ImageDropzone
                  v-if="projectPrepare"
                  ref="documents"
                  :files="files"
                  max-files="3"
                />
              </b-form-group>

              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2 mr-1 text-uppercase"
                >
                  Guardar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BInputGroup,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp, TimestampToFlatPickr } from "@/libs/helpers";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import { config } from "@/shared/app.config";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    ImageDropzone,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      statusProjectsText: config.statusProjectsText,
      name: "",
      files: [],
      required,
      email,
      projectPrepare: false,
      items: [],
      location: "",
      category: "",
      product: "",
      parent: {},
    };
  },
  computed: {
    ...mapGetters({
      asset: "assets/getAsset",
      project: "projects/getProject",
      selectCategories: "categories/getSelectCategories",
      selectCategoriesParent: "categories/getSelectCategories",
      selectProducts: "products/getSelectProductos",
    }),
  },
  watch: {
    category() {
      if (this.category) {
        if (this.category.id !== this.asset.category.id) {
          this.product = "";
        }

        this.getSelectProducts({
          page: "",
          per_page: 99999,
          search: "",
          calculator: false,
          type: "",
          category: this.category.id,
        });
      } else {
        this.product = "";
        this.$refs.products.options.splice(
          0,
          this.$refs.products.options.length
        );
      }
    },
  },
  methods: {
    ...mapActions({
      edit: "assets/edit",
      getAsset: "assets/getAsset",
      getProject: "projects/getProject",
      getSelectCategories: "categories/getSelectCategories",
      getSelectCategoriesParent: "categories/getSelectCategories",
      getSelectProducts: "products/selectProducts",
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    handleSubmit() {
      this.$refs.editAsset.validate().then((success) => {
        if (success) {
          const { id } = this.asset;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, asset: formData });
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      });
    },
    setData() {
      if (this.asset.documents_asset && this.asset.documents_asset.length > 0) {
        const aux = this;
        this.asset.documents_asset.forEach((element, index) => {
          const file = {
            name: `File ${index + 1}`,
            url: element.path,
            urlDelete: `/assets/delete_doc_asset/${aux.asset.id}/${element.id}`,
          };
          this.files.push(file);
        });
      }

      if (this.asset.location != null) {
        this.location = this.asset.location;
      }
      if (this.asset.category != null) {
        this.category = this.asset.category;
      }
      if (this.asset.category_parent != null) {
        this.parent = this.asset.category_parent.name[this.currentLanguage];
      }
      if (this.asset.product != null) {
        this.product = this.asset.product;
      }
      this.projectPrepare = true;
    },
    createFormData() {
      const data = this.$refs.documents.getFormData("documents_asset");
      // const data = new FormData()
      data.append("name", this.asset.name);
      data.append("description", this.asset.description);

      data.append("user_id", this.asset.user.id);
      data.append("client_id", this.asset.client.id);

      if (this.product) {
        data.append("product_id", this.product.id);
      }

      if (this.location) {
        data.append("location_id", this.location.id);
      }

      return data;
    },
  },
  async created() {
    await this.getAsset(this.$route.params.id);
    await this.getProject(this.asset.project.id);
    await this.getSelectCategoriesParent({ parent:0 });
    await this.setData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.icon-trash {
  padding-top: 7px;
}
</style>
